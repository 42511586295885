import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculateService {

  constructor() { }

  public cardToPoints(card: string) {
    if(card.length==3){
      return 10;
    }
    if (isNaN(Number(card[0]))) {
      switch (card[0]) {
        case 'J':
          return 11;
        case 'Q':
          return 12;
        case 'K':
          return 13;
        case 'A':
          return 14;
      }
    } else {
      return Number(card[0]);
    }
  }

  public getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  public getColorOfCard(card: string) {
    if (card[1] == "S" || card[1] == "C") {
      return "black";
    } else {
      return "red";
    }
  }
}
