import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private storage: Storage, private httpClient: HttpClient) {
    this.storage.create();
  }

  public async translatePage(page: string) {
    let language = await this.storage.get("i18n");
    switch (language) {
      case "de":
        let de = await this.httpClient.get<any>('assets/i18n/de.json').toPromise();
        return de[page];
      default:
        let en = await this.httpClient.get<any>('assets/i18n/en.json').toPromise();
        return en[page];
    }
  }
  public async translatePageLanugageForce(page: string, i18n: string) {
    return this.translatePage(page);
  }
}
